/* eslint-disable arrow-body-style */
import React from 'react';
import { DialogContent } from 'components/ui/dialog';
import { Button } from 'components/ui/button';

const DuplicateForm = ({ onClose, handleOnClick }) => {
  return (
    <DialogContent hideCloseButton className="sm:max-w-[425px] max-w-sm">
      <div>
        <h1 className="text-[20px]">Duplicate Signature</h1>
        <p className="font-medium text-[14px] m-0">
          Do you want to duplicate this Signature?
        </p>
        <div className="flex justify-end gap-3 pt-4">
          <Button
            className="w-[max-content]"
            onClick={() => onClose()}
            variant="outline"
            size="sm"
          >
            Cancel
          </Button>
          <Button onClick={handleOnClick} size="sm">
            Duplicate
          </Button>
        </div>
      </div>
    </DialogContent>
  );
};

export default DuplicateForm;
