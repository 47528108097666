import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'hooks/use-toast';
import { ChevronRight, Zap } from 'lucide-react';
import { replace } from 'lodash';
import React, { useState } from 'react';
import { useAppContext } from 'AppContext';
import client from 'apollo';
import { fileUpload, getLiveUrl } from 'common/utils';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import { useRouter } from 'hooks/router';
import {
  GET_STYLES,
  HEAD_SHOT_SIGNED_URL,
} from 'modules/Headshot/graphql/Queries';

import PreparingHeadshot from 'components/PreparingHeadshot';
import Button from 'components/Button';
import SideCoverHeadshot from 'assets/images/side-cover-headshot.png';
import {
  CREATE_HEADSHOT,
  PROCCESS_HEADSHOT_IMAGE,
} from 'modules/Headshot/graphql/Mutations';
import { useGetHeadshot } from 'hooks/headshot';
import Cropper from 'components/Cropper';
import ImageUploadPreview from 'components/ImageUploadPreview';
import PublicHeadshotEditor from './PublicHeadshotEditor';

const PublicHeadShot = () => {
  const {
    state: { loading, signature, headshot },
    setSignature,
    setLoading,
    setHeadshotUpdateStatus,
  } = useAppContext();

  const { navigate } = useRouter();
  const [isUploadFailed, setIsUploadFailed] = useState(false);
  const [getHeadshot] = useGetHeadshot();

  const { data: { styles: { data: stylesData } = {} } = {} } = useQuery(
    GET_STYLES,
    {
      fetchPolicy: 'network-only',
    },
  );

  const [createHeadshot] = useMutation(CREATE_HEADSHOT, {
    onCompleted: async (res) => {
      await getHeadshot({
        variables: { where: { id: res?.createHeadshot?.data?.id } },
      });

      setSignature({
        ...signature,
        headshotId: res?.createHeadshot?.data?.id,
      });

      setHeadshotUpdateStatus(true);
      navigate('/try-headshot');
    },
    onError() {
      setLoading(false);
    },
  });

  const [processHeadshot] = useMutation(PROCCESS_HEADSHOT_IMAGE, {
    onCompleted: (res) => {
      setLoading(true);
      if (res?.processHeadshotImage) {
        const outputImage = getLiveUrl(res?.processHeadshotImage?.key);

        createHeadshot({
          variables: {
            data: {
              image: outputImage || res?.processHeadshotImage?.key,
            },
          },
        });
      }
    },
    onError: () => {
      setLoading(false);
    },
  });
  const form = useForm();

  const handleImageUpload = async (file = null) => {
    const fileName = replace(file?.[0]?.name, new RegExp(' ', 'g'), '_');
    const res = await client?.query({
      query: HEAD_SHOT_SIGNED_URL,
      fetchPolicy: 'network-only',
      variables: {
        data: {
          fileName,
        },
      },
    });
    const { signedUrl = '', key = '' } =
      await res?.data?.getHeadshotUploadSignedUrl;

    try {
      await fileUpload(signedUrl, file?.[0]);
      await processHeadshot({
        variables: {
          where: {
            key,
          },
        },
      });

      // navigate(`/onboarding/try-headshot/${id}`);
    } catch (error) {
      setIsUploadFailed(true);
      // message?.error(error?.message);
      toast({
        closeicn: 'destructive',
        description: error?.message,
      });
    }
  };

  const handleFinish = async (values) => {
    const { uploadPicture } = values;
    setLoading(true);
    setIsUploadFailed(false);
    await handleImageUpload(uploadPicture);
    setLoading(false);
  };

  if (!isUploadFailed && loading) {
    return <PreparingHeadshot />;
  }
  if (headshot?.id) {
    return <PublicHeadshotEditor />;
  }

  const uploadPicture = form.watch('uploadPicture');

  return (
    <div className="flex w-full min-h-screen h-full max-h-screen">
      <div className="flex  flex-col justify-center w-full bg-primary-foreground p-[16px]">
        <div className="w-full max-w-[600px] mx-auto">
          <img src="/logo.svg" height={40} width={200} alt="logo" />
          <h1
            level={1}
            className="text-primary font-primary  text-[32px] font-semibold leading-[36px] mt-[60px] mb-[60px]"
          >
            Professional headshot
          </h1>

          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleFinish)}
              form={form}
              initialValues={{ remember: true }}
            >
              <FormField
                control={form.control}
                name="uploadPicture"
                className="mb-[16px]"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Upload your picture</FormLabel>
                    <FormControl>
                      <>
                        <div className="block text-medium-base font-primary py-4" />

                        {field.value ? (
                          <ImageUploadPreview
                            text="Clear"
                            onChange={() => {
                              form.setValue(field.name, '');
                            }}
                            imageUrl={field.value[0].preview}
                          />
                        ) : (
                          <Cropper
                            onOk={(file) => {
                              field.onChange(file);
                              form.setValue(field.name, [file]);
                            }}
                            baseDraggerClassname="h-[102px] bg-[rgba(0,0,0,0.02)]"
                          />
                        )}
                      </>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button
                htmlType="submit"
                className="h-[40px] w-full  mt-2"
                disabled={!uploadPicture || uploadPicture.length === 0}
                loading={!isUploadFailed && loading}
              >
                <div className="flex items-center space-x-1">
                  <span>Next</span>
                  <ChevronRight width="20" height="20" />
                </div>
              </Button>
            </form>
          </Form>
          {/* <div className="w-full flex justify-center mt-[20px]">
            <AiHeadshotButton
              onClick={() => {
                navigate(`/auth/signup?r=ai-headshot`);
              }}
              className="flex justify-center bg-gradient-to-r from-[#8557E9] via-[#BC57E6] to-[#F357E3]"
            >
              Try AI Headshot
              <Zap size={16} className="ml-[6px] fill-white-0" />
            </AiHeadshotButton>
          </div> */}
        </div>
      </div>
      <div
        className="lg:hidden flex w-1/2 h-full min-h-screen gap-[10px] shrink-0 overflow-hidden bg-cover bg-no-repeat bg-center md:w-0 md:h-full"
        style={{
          backgroundImage: `url(${SideCoverHeadshot})`,
        }}
      />
    </div>
  );
};

export default PublicHeadShot;
