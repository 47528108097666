/* eslint-disable no-console */
/* eslint-disable no-undef */
import 'react-image-crop/dist/ReactCrop.css';
import { Loader2, BoxSelect, Circle } from 'lucide-react';
import BaseDragger from 'components/BaseDragger';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogOverlay } from 'components/ui/dialog';
import { Slider } from 'components/ui/slider';
import { IMAGE_TYPE } from 'common/constants';
import { cn } from 'lib/utils';
import React, {
  useState,
  useRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
} from 'react';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  convertToPixelCrop,
} from 'react-image-crop';
import { canvasPreview } from './canvasPreview';
import { useDebounceEffect } from './useDebounceEffect';

// Helper function to center aspect crop
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

const Cropper = forwardRef(
  (
    {
      onOk,
      cropperClass,
      baseDraggerClassname,
      draggerContent,
      cropperProps: initialCroperProps = {},
      showAspectbuttons = false,
      allowGif = false,
    },
    ref,
  ) => {
    const [cropperProps, setCropperProps] = useState(initialCroperProps);

    const { circularCrop = false } = cropperProps;
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [open, setOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState('');
    const imgRef = useRef(null);
    const blobUrlRef = useRef('');
    const previewCanvasRef = useRef(null);
    const hiddenAnchorRef = useRef(null);
    const [completedCrop, setCompletedCrop] = useState(null);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [aspect, setAspect] = useState(null);
    const [loading, setLoading] = useState(false);

    useImperativeHandle(ref, () => ({
      open: () => setOpen(true),
      close: () => setOpen(false),
    }));

    const handleCropshape = (props) => {
      const { aspect: newAspect, circularCrop: newCircularCrop } = props;
      setCropperProps((prev) => ({ ...prev, ...props }));

      if (imgRef.current) {
        const { width, height } = imgRef.current;
        const newCrop = centerAspectCrop(
          width,
          height,
          newAspect !== null ? newAspect : 1,
        );
        setCrop(newCrop);
        setCompletedCrop(convertToPixelCrop(newCrop, width, height));
      }
    };

    function onSelectFile(files) {
      const file = files[0];
      if (file.type === IMAGE_TYPE.gif) {
        // Directly use the file without any processing
        const newFile = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        setImgSrc(newFile.preview);
        setOpen(false); // Don't open the cropper dialog for GIFs
        if (onOk) {
          onOk(newFile); // Pass the file to your upload handler
        }

        return; // Bypass further processing
      }
      if (files.length > 0) {
        setOpen(true);
        setCrop(undefined); // Makes crop preview update between images.
        const reader = new FileReader();
        reader.addEventListener('load', () =>
          setImgSrc(reader.result?.toString() || ''),
        );
        reader.readAsDataURL(files[0]);
      }
    }

    function onImageLoad(e) {
      // if (aspect !== '') {
      //   handleCropshape({ aspect: null, circularCrop: false });
      // }
      const imageWidth = e.target.offsetWidth;
      const imageHeight = e.target.offsetHeight;
      // Set crop to cover the entire image
      const pixelCrop = {
        unit: '%', // Using percentage to cover the entire image regardless of its actual pixel size
        width: 50,
        height: 50,
        x: 25,
        y: 25,
      };
      // Set the crop state and ensure the aspect ratio is null to not constrain the crop dimensions
      handleCropshape({ aspect: null, circularCrop: false });
      setCrop(pixelCrop);
      setCompletedCrop(convertToPixelCrop(pixelCrop, imageWidth, imageHeight));
    }

    async function onDownloadCropClick() {
      setLoading(true);
      const image = imgRef.current;
      const previewCanvas = previewCanvasRef.current;

      if (!image || !previewCanvas || !completedCrop) {
        console.error('Crop canvas does not exist');
        return;
      }

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      const offscreen = new OffscreenCanvas(
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
      );
      const ctx = offscreen.getContext('2d');
      if (!ctx) {
        throw new Error('No 2d context');
      }
      if (circularCrop) {
        ctx.beginPath();
        ctx.arc(
          offscreen.width / 2,
          offscreen.height / 2,
          Math.min(offscreen.width, offscreen.height) / 2,
          0,
          Math.PI * 2,
        );
        ctx.clip();
      }

      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height,
      );

      const blob = await offscreen.convertToBlob({
        type: 'image/png',
      });

      const file = new File([blob], 'cropped-image.png', { type: 'image/png' });

      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);

      if (hiddenAnchorRef.current) {
        hiddenAnchorRef.current.href = blobUrlRef.current;
        hiddenAnchorRef.current.click();
      }

      if (onOk) {
        const newFiles = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
        onOk(newFiles);
      }
      setLoading(false);
    }

    useDebounceEffect(
      async () => {
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            completedCrop,
            scale,
            rotate,
          );
        }
      },
      100,
      [completedCrop, scale, rotate],
    );

    return (
      <>
        <BaseDragger
          className={baseDraggerClassname}
          handleChange={(file) => {
            onSelectFile(file);
          }}
          allowGif={allowGif}
        >
          {draggerContent}
        </BaseDragger>

        <Dialog
          open={open}
          onOpenChange={setOpen}
          maxWidth="sm"
          className=""
          fullWidth
        >
          <DialogOverlay className="bg-[black]/80" />
          <DialogContent
            className={cn(
              'p-0 gap-0 max-w-[350px] max-h-[calc(100vh_-_10px)] overflow-auto',
              cropperClass,
            )}
            hideCloseButton
          >
            <div className="p-2">
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                  background: '#333',
                  // maxHeight: '80vh', // Set max-height for the container
                  // overflow: 'hidden', // Ensure it doesn't overflow
                }}
              >
                <ReactCrop
                  crop={crop}
                  keepSelection
                  onChange={(_, percentCrop) => setCrop(percentCrop)}
                  onComplete={(c) => setCompletedCrop(c)}
                  aspect={aspect}
                  className="w-full flex items-center justify-center"
                  {...cropperProps}
                >
                  <img
                    ref={imgRef}
                    alt="Crop me"
                    src={imgSrc}
                    style={{
                      objectFit: 'contain',
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                    width="100%"
                  />
                </ReactCrop>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    display: 'none',
                    border: '1px solid black',
                    objectFit: 'contain',
                    width: completedCrop?.width,
                    height: completedCrop?.height,
                  }}
                />
              </div>
            </div>
            {showAspectbuttons && (
              <div className="flex gap-2 p-2">
                <Button
                  onClick={() =>
                    handleCropshape({ aspect: null, circularCrop: false })
                  }
                  variant="outlineshort"
                  size="iconchange"
                >
                  <BoxSelect className="h-5 w-5 " />
                </Button>
                <Button
                  onClick={() =>
                    handleCropshape({ aspect: 1 / 1, circularCrop: false })
                  }
                  variant="outlineshortborder"
                  size="iconchange"
                >
                  1 : 1
                </Button>
                <Button
                  onClick={() =>
                    handleCropshape({ aspect: 9 / 16, circularCrop: false })
                  }
                  variant="outlineshortborder"
                  size="iconchange"
                >
                  2 : 3
                </Button>
                <Button
                  onClick={() =>
                    handleCropshape({ aspect: 16 / 9, circularCrop: false })
                  }
                  variant="outlineshortborder"
                  size="iconchange"
                >
                  3 : 2
                </Button>
                <Button
                  onClick={() =>
                    handleCropshape({ aspect: 1 / 1, circularCrop: true })
                  }
                  variant="outlineshort"
                  size="iconchange"
                >
                  <Circle className="h-5 w-5 " />
                </Button>
              </div>
            )}
            {/* <div className="p-4">
              <label htmlFor="radius">Radius: {radius}px</label>
              <Slider
                defaultValue={[0]}
                min={0}
                max={50}
                step={1}
                className="w-full"
                onValueChange={([newRadius]) => {
                  setRadius(newRadius);
                }}
              />
            </div> */}
            <div className="p-4">
              <Slider
                defaultValue={[1]}
                min={1}
                max={3}
                step={0.1}
                className="w-full"
                onValueChange={([zoom]) => {
                  setScale(zoom);
                }}
              />
            </div>
            <div className="flex gap-2 justify-end p-4">
              <Button onClick={() => onDownloadCropClick()} size="sm">
                {loading && <Loader2 className="animate-spin mr-2 h-5 w-5 " />}
                Ok
              </Button>
              <Button
                onClick={() => {
                  setOpen(false);
                  setImgSrc('');
                }}
                size="sm"
              >
                Remove
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  },
);

export default Cropper;
