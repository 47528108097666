/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { get } from 'lodash';

// Zod validation schema
const signatureSchema = z.object({
  name: z.string().min(1, 'Please enter signature name'),
});

const UpdateForm = ({ handleOnClick, data, onClose }) => {
  const form = useForm({
    resolver: zodResolver(signatureSchema),
    defaultValues: {
      name: data?.name ?? '',
    },
  });

  useEffect(() => {
    if (data) {
      form.reset({ name: data.name });
    }
  }, [data, form]);

  const onSubmit = async (values) => {
    const { name } = values;
    await handleOnClick(name);
  };

  return (
    <DialogContent className="sm:max-w-[425px] max-w-sm" hideCloseButton>
      <DialogHeader>
        <DialogTitle>Update Signature</DialogTitle>
      </DialogHeader>

      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid gap-[18px]">
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Signature Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Signature Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex justify-end gap-3 ">
              <Button
                type="button"
                className="w-[max-content]"
                onClick={() => onClose()}
                variant="outline"
                size="sm"
              >
                Cancel
              </Button>
              <Button type="submit" size="sm">
                Update
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </DialogContent>
  );
};

export default UpdateForm;
